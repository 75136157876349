import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { iff } from '../../utils/iff';

export const Loader = (props) => {
  const { t } = useTranslation();
  return (
    <div style={props.style}>
      {iff(props.mask, <div className='mask' />)}
      <div className='loader-wrapper'>
        {iff(
          props.showMessage,
          <div className='loader-text'>{t(props.message || 'Loading...')}</div>
        )}
        <div className='loader' />
      </div>
    </div>
  );
};

Loader.propTypes = {
  message: PropTypes.string,
  style: PropTypes.object,
  mask: PropTypes.bool,
  showMessage: PropTypes.bool
};

Loader.defaultProps = {
  mask: true,
  showMessage: true
};

// import React from 'react';
// import { Spin } from 'syngenta-digital-cropwise-react-ui-kit';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// const StyledSpin = styled(Spin)`
//   right: 0;
//   left: 0;
//   top: 50%;
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   background: #222222ab;
//   z-index: 9999;
// `;

// export const Loader = ({ message }) => (
//   <div style={{ background: '#252323', height: '100vh' }}>
//     <StyledSpin size='large' tip={message || 'Loading...'} />
//   </div>
// );

// Loader.propTypes = {
//   message: PropTypes.string
// };
